import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import XLSX from "xlsx";
import { PopupWraper } from "../../../components/popup";
import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, ITableStructureItem, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withUserWraper } from "../wraper";
import "./UserList.scss";
import { Routes } from "../../../AppRoutes";
import { useHistory } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "./styles.css";

export const UserList = withUserWraper(
  memo(() => {
    const history = useHistory();
    const countries = useSelector((state) => state.countries);
    const [params, setParams] = useState([] as any[]);
    const [editData, setEditData] = useState(null);
    const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
    const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
    const user = useSelector((state) => state.user);
    const [summary, setSummary] = useState<any>(null);
    const [openLightboxData, setOpenLightboxData] = useState<any>();
    const [agentList, setAgentList] = useState<any>();

    const structure: ITableStructureItem[] = [
        {
          name: "C9 ID",
          key: "userId",
          render: (item) => item?.userId ?? 'N/A',
          copyable: (item) => item?.userId,
        },
        {
            name: "FULL NAME",
            key: "firstName",
            render: (item) => <span style={{color: '#4680FF'}}>{item?.firstName ?? 'N/A'}</span>,
            copyable: (item) => item?.firstName,
        },
        {
            name: "NICKNAME",
            key: "nick",
            render: (item) => item?.nick ?? 'N/A',
            copyable: (item) => item?.nick,
        },
        {
          name: "EMAIL",
          key: "email",
          render: (item) => {
            return <span className="email">{item.email}</span>;
          },
          copyable: (item) => item?.email,
        },
        {
            name: "PHONE",
            key: "phoneNumber",
            render: (item) => item?.phoneNumber ?? 'N/A',
            copyable: (item) => item?.phoneNumber,
        },
        {
          name: "IDENTITY CARD ID/\nPASSPORT",
          key: "idCard",
          render: (item) => (
            <div className="id-card">
              <div className="id-card__label">{item?.idCard ?? "N/A"}</div>
              {(JSON.parse(item?.imageUrls ?? '[]')?.length > 0) && (
                <div className="id-card__icon" onClick={() => setOpenLightboxData(JSON.parse(item?.imageUrls ?? '[]'))}><Icon.Visible /></div>
              )}
            </div>
          ),
          copyable: (item) => item?.idCard,
        },
        {
            name: "NATIONALITY",
            key: "countryId",
            render: (item) => (item?.countryId != null) ? countries?.data?.find((x:any) => x.countryId === item?.countryId)?.niceName : 'N/A',
            copyable: (item) => (item?.countryId != null) ? countries?.data?.find((x:any) => x.countryId === item?.countryId)?.niceName : 'N/A',
        },
        {
          name: "AGENT NAME",
          key: "agentName",
          render: (item) => item?.agentName ?? 'N/A',
          copyable: (item) => item?.agentName,
        },
        {
            name: "TOURS",
            key: "totalTournaments",
            render: (item) => item?.totalTournaments ?? 'N/A',
        },
        {
          name: "ROLE",
          key: "userRole",
          render: (item) => item?.userRole ?? 'N/A',
        },
        {
          name: "ACTIONS",
          key: "actions",
          render: (item, fetchData) => {
            return (
              <div className="action">
                <div className="action__edit" title="Edit user" onClick={() => history.push(Routes.userEdit.renderPath(item?.userId))}>
                  <Icon.ActionEditIcon />
                </div>
              </div>
            );
          },
        },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListUser({
            ...params,
            page: 1,
            numberOfItemsPerPage: 10000,
          });

          const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (!column.key) return "";
                if (column.key === "countryId") return countries?.data?.find((x:any) => x.countryId === item?.countryId)?.niceName;
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `User List ${now.toLocaleDateString().replace(/\//g, "-")} ${now
              .toLocaleTimeString()
              .replace(/:/g, "-")}.xlsx`,
            { type: "binary" }
          );

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error) {
          // @ts-ignore
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    useEffect(() => {
      AdminService.getAgentList().then((res) => {
        setAgentList((res?.result?.agents?.AGENTS ?? [])?.map((x: any) => ({ label: x.AGENT_NAME, value: x.AGENT_ID })));
      });
    }, []);

    if (agentList == null) return null;

    return (
      <div className="UserList">
        <div className="button-container">
          <Button
            className="mb20"
            label="Export to Excel"
            buttonType="success"
            onClick={handleExportExcel}
          />
          <div className="infor-container">
            <div className="pending-verification">
              <div className="pending-verification__icon">
                <Icon.UserPendingVerification />
              </div>
              <div className="pending-verification__content">
                {summary?.find((x: any) => x.approvalStatus === "PENDING")?.total ?? 0}
                <span className="sub-text">&nbsp;PENDING REVIEW</span>
              </div>
            </div>
            <div className="user-confirmed">
              <div className="user-confirmed__icon">
                <Icon.UserConfirmed />
              </div>
              <div className="user-confirmed__content">
                {summary?.find((x: any) => x.approvalStatus === "APPROVED")?.total ?? 0}
                <span className="sub-text">&nbsp;REVIEWED</span>
              </div>
            </div>
          </div>
        </div>

        <Table
          isOpenFilter
          hasOrderColumn
          hasSearchButton
          filters={[
              {
                name: "Search",
                key: "keyword",
                input: e => <TableFilterInputText {...e} placeholder="Name, Email" />,
              },
              {
                name: "Agent Name",
                key: "agentId",
                input: (e) => (
                  <TableFilterInputSelect
                    {...e}
                    options={agentList}
                    isClearable={true}
                    isSearchable={false}
                  />
                ),
              },
          ]}
          structure={structure}
          fetchData={async (params) => {
            setParams(params);
            return AdminService.getListUser({
              page: params.pageNumber,
              numberOfItemsPerPage: params.limit,
              ...params,
            }).then((res) => {
              setSummary(res?.summary);
              return res;
            });
          }}
          // forceUpdateTable={forceUpdateTable}
        />
        <Lightbox
          open={openLightboxData != null}
          close={() => setOpenLightboxData(null)}
          slides={[
            ...(openLightboxData ?? []).map((x: any) => ({ src: x })),
          ]}
        />
        {/* {editData && (
          <PopupWraper center title="EDIT USER" onClose={() => setEditData(null)}>
            <EditUser
              data={editData}
              onFinishEdit={() => {
                setForceUpdateTable(Math.random);
                setEditData(null);
              }}
            />
          </PopupWraper>
        )} */}
        {/* {freezeDataId && (
          <PopupWraper center title="Freeze user" onClose={() => setFreezeDataId(null)}>
            <Ban
              userId={freezeDataId}
              // onClick={async () => {
              //     try {
              //         // userId
              //         console.log("here");
              //         if (user.userId === userId)
              //             throw new Error(
              //                 "Can't Freeze Yourself"
              //             );

              //         if (freezeDataId) {
              //             const res = await AdminService.freezeUsers(
              //                 [freezeData.userId]
              //             );
              //             const { success } = res;
              //             if (success) {
              //                 CreateAlert({
              //                     type: "success",
              //                     message: "Freeze data success.",
              //                 });
              //                 setFreezeData(null);
              //             }
              //         }
              //     } catch (error) {
              //         CreateAlert({
              //             type: "danger",
              //             message:
              //                 error?.message ||
              //                 "Freeze data fail.",
              //         });
              //     }
              // }}
            />
          </PopupWraper>
        )} */}
      </div>
    );
  })
);
