import React, { FC } from 'react'
import Select from 'react-select'
import { SelectComponentsProps } from 'react-select/src/Select'

import { IInputProps, ObjectUtils } from '../../../modules'
import { isEqual } from '../../../modules'

interface IAsyncDataConfig {
    pathLabel: string,
    pathValue: string,
    response: any,
}

interface IInputSelect extends IInputProps {
    options?: any,
    isClearable?: boolean,
    asyncData?: IAsyncDataConfig,
    isMulti?: boolean,
    menuPlacement?: any,
}

export const InputSelect: FC<IInputSelect> = (props) => {
    const { asyncData } = props;

    const generalProps: SelectComponentsProps = {
        isMulti: props.isMulti,
        className: 'InputSelect',
        classNamePrefix: 'InputSelect',
        isSearchable: true,
        placeholder: '-- Select --',
        onChange: (data: any) => {
            if (props.isMulti) props.onChange((data || []).map((v: any) => v.value))
            else props.onChange(data?.value);
        },
        defaultValue: props.defaultValue,
        onBlur: () => setTimeout(() => props.onTouched(), 500),
        isClearable: typeof props.isClearable === 'boolean' ? props.isClearable : true,
        isDisabled: props.isDisabled,
        autoFocus: false,
        menuPlacement: props.menuPlacement ?? 'bottom',
    }

    if (asyncData) {
        const { pathLabel, pathValue, response } = asyncData;
        const errorMessage = ObjectUtils.getIn(response, 'error.message');

        if (!response) return <input disabled defaultValue="Fetching options..." />
        if (errorMessage) return <input disabled defaultValue={`Error: ${errorMessage}`} />

        const options = ObjectUtils.getIn(response, 'data', [], arr => arr.map((item: any) => ({
            label: ObjectUtils.getIn(item, pathLabel),
            value: ObjectUtils.getIn(item, pathValue, ''),
        })))

        const getValue = () => {
            if (props.isMulti) {
                return (options || []).filter((v: any) => props.value.includes(v.value))
            }
            return props.value ? options.find((v: any) => isEqual(props.value, v.value)) : ''
        }

        return <Select
            {...generalProps}
            options={options}
            value={getValue()}
        />
    }

    const getValue = () => {
        if (props.isMulti) return (props.options || []).filter((v: any) => props.value.includes(v.value))
        return props.value ? props.options.find((v: any) => isEqual(props.value, v.value)) : ''
    }

    return (
        <Select
            {...generalProps}
            options={props.options}
            value={getValue()}
        />
    )
}

InputSelect.defaultProps = {
    isMulti: false,
}