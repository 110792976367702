import { RequestMainService } from "../request";
import { Store } from "redux";
import { SET_COIN_CONVERSION_RATE } from "./coinConversionRate.reducer";
import capitalize from "lodash/capitalize";
export interface IRequestWithdrawPayload {
  coinId: number;
  feeCoinId: number;
  value: number;
}

export class BankService {
  static async getTransactions(params: any = {}) {
    return RequestMainService.get(`/bank/transaction`, params).then(({ result }) => ({
      count: result.total,
      data: result.transactions,
    }));
  }

  static async getCoinConversionRate(store: Store) {
    return RequestMainService.get(`/bank/rates-to-usd`)
      .then(({ result }) => store.dispatch({ type: SET_COIN_CONVERSION_RATE, data: result }))
      .catch((res) =>
        store.dispatch({
          type: SET_COIN_CONVERSION_RATE,
          error: res.error,
        })
      );
  }

  static getTransactionTypeOptions() {
    return [
      // { label: "INIT", value: 0 },
      // { label: "DEPOSIT", value: 1 },
      // { label: "WITHDRAW", value: 2 },
      // { label: "RECEIVE_TRANSFER", value: 3 },
      // { label: "SEND_TRANSFER", value: 4 },
      // { label: "DEPOSIT_FEE", value: 5 },
      // { label: "WITHDRAW_FEE", value: 6 },
      // { label: "SEND_TRANSFER_FEE", value: 7 },
      // { label: "RECEIVE_TRANSFER_FEE", value: 8 },
      // { label: "TRADE_FEE", value: 9 },
      // { label: "OTHER_INCOME", value: 10 },
      // { label: "OTHER_FEE", value: 11 },
      // { label: "BUY_PRESALE_COIN", value: 12 },
      // { label: "REFUND_WITHDRAW_FEE", value: 13 },
      // { label: "DIRECT_COMMISSION", value: 14 },
      // { label: "BUY_AGENCY_LICENSE", value: 15 },
      // { label: "UPGRADE_MINER", value: 16 },
      { label: "BANK_RECEIVE_FROM_USER", value: 17 },
      { label: "BANK_SEND_TO_USER", value: 18 },
      // { label: "CONVERT_SENT", value: 19 },
      // { label: "CONVERT_RECEIVED", value: 20 },
      // { label: "SEND_EXCHANGE_FEE", value: 21 },
      // { label: "BUY_MINER_COMMISSION", value: 22 },
      // { label: "UPGRADE_MINER_COMMISSION", value: 23 },
      // { label: "SYSTEM_COMMISSION", value: 24 },
      // { label: "CASH_BACK", value: 25 },
      // { label: "RANK_COMMISSION", value: 26 },
      // { label: "REFUND_WITHDRAW", value: 27 },
      // { label: "REFILL_DEMO_ACCOUNT", value: 28 },
      // { label: "RETURN_MINER_FEE", value: 29 },
      // { label: "PLACE_GAME", value: 46 },
      // { label: "GAME_PROFIT", value: 47 },
      // { label: "AIRDROP_TOKEN", value: 48 },
      // { label: "AIRDROP_TOKEN_FROM_USER", value: 49 },
      // { label: "EXPERIENCE_GAME_REFUND", value: 50 },
      // { label: "CANCEL_GAME", value: 52 },
      // { label: "EXCHANGE_OUT", value: 53 },
      // { label: "EXCHANGE_OUT_FEE", value: 54 },
      // { label: "EXCHANGE_IN", value: 55 },
      { label: "RECOVERY", value: 56 },
      // { label: "GAME_ADJUST", value: 57 },
      // { label: "GAME_TIP", value: 58 },
      // { label: "GAME_GIVE_PROMOTION", value: 59 },
      // { label: "GAME_REFUND", value: 60 },
      // { label: "GAME_CANCEL_TIP", value: 61 },
      // { label: "EXPERIENCE_GAME_FEE", value: 62 },
      // { label: "CIC_AIRDROP", value: 63 },
      // { label: "CIC_AIRDROP_FROM_F1", value: 64 }
      // { label: "AIRDROP_TOKEN_COMMISSION", value: 68 },
      // { label: "AIRDROP_TOKEN_COMMISSION_TO_USER", value: 69 },
      // { label: "USER_PROMOTION", value: 70 },
      // { label: "DEPOSIT_BONUS", value: 72 },
      // { label: "LOSE_7", value: 73 },
      // { label: "LOSE_9", value: 74 }
      { label: "TOP_UP", value: 78 },
      { label: "BUY_IN", value: 79 },
      { label: "BUY_PACKAGE", value: 80 },
    ].map((v) => ({ ...v, label: capitalize(v.label.replace(/_/g, " ")) }));
  }

  static async transferRequest(payload: any) {
    return RequestMainService.post(`/bank/transfer-request`, payload);
  }

  static async bankDeviceChangeHistory(payload: any) {
    return RequestMainService.get(`/admin/dig/bank-device-change-history`, payload).then(({ result }) => ({
      count: result.data.length,
      data: result.data,
    }));
  }

  static async getBankAccount(payload: any) {
    return RequestMainService.get(`/admin/dig/bank-account-get`, payload).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }

  static async getBankAccountAvailable() {
    return RequestMainService.get(`/admin/dig/bank-account-available`).then(({ result }) => result);
  }

  static async getBankAccountNoneAvailable() {
    return RequestMainService.get(`/admin/dig/bank-account-nonavailable`).then(({ result }) => result);
  }

  static async getBankAccountFilter() {
    return RequestMainService.get(`/admin/dig/bank-account-nonavailable`).then(({ result }) =>
      RequestMainService.get(`/admin/dig/bank-account-available`).then((res) => [...result, ...res.result])
    );
  }

  static async getDeviceTable() {
    return RequestMainService.get(`/admin/dig/device-get`).then(({ result }) => ({
      count: result.length,
      data: result,
    }));
  }

  static async bankDeviceChange(payload: any) {
    return RequestMainService.post(`/admin/dig/device/bank-device-change`, payload);
  }

  static async bankTransfer(payload: any) {
    return RequestMainService.post(`/admin/dig/bank-account-transfer`, payload);
  }

  static async bankMatching(payload: any) {
    return RequestMainService.post(`/admin/dig/bank-match`, payload);
  }

  static async getListBankMatching(params: any = {}) {
    return RequestMainService.get(`/admin/dig/bank-match-history`, params).then(({ result }) => {
      return {
        count: result?.total,
        data: result?.data,
      };
    });
  }
}
