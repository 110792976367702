import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { InputDateTime, InputNumber, InputSelect, InputText } from "../../../components";
import { InputImageSingle } from "../../../components/single-image";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Routes } from "../../../AppRoutes";
import currencyCodes from 'currency-codes';

export const currencyOptions = currencyCodes.codes().map(code => {
    const currency = currencyCodes.code(code);
    return {
        label: `${currency?.code} - ${currency?.currency} (${currency?.countries.join(', ') || 'N/A'})`,
        value: currency?.code
    };
});

export const organizationList = [
    { label: "APL", value: "APL" },
    { label: "APPT", value: "APPT" },
    { label: "APT", value: "APT" },
    { label: "EPT", value: "EPT" },
    { label: "Player Series", value: "Player Series" },
    { label: "Poker Dream", value: "Poker Dream" },
    { label: "SCOOP", value: "SCOOP" },
    { label: "USOP", value: "USOP" },
    { label: "WPT", value: "WPT" },
    { label: "WSOP", value: "WSOP" },
    { label: "WSOP-C", value: "WSOP-C" },
];

export const TournamentCreatePage = () => {
    const history = useHistory();

    const { getInputProps, handleSubmit } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "tourName",
                validate: Yup.string().required("Must be provided"),
            },
            {
                name: "location",
                validate: Yup.string().required("Must be provided"),
            },
            {
                name: "organization",
                validate: Yup.string().required("Must be provided"),
            },
            {
                name: "startTime",
                validate: Yup.string().required("Must be provided"),
            },
            {
                name: "endTime",
                validate: Yup.string().required("Must be provided"),
            },
            {
                name: "prize",
                validate: Yup.number()
                    .typeError("Must be a number")
                    .required("Must be provided")
                    .moreThan(0, "Must be greater than 0"),
            },
            {
                name: "currency",
                validate: Yup.string().required("Must be provided"),
                defaultValue: "VND",
                isDisabled: true,
            },
            {
                name: "promotion",
                validate: Yup.string().required("Must be provided"),
            },
        ],
        onSubmit: async (values: any) => {
            let payload = {
                tourName: values?.tourName,
                location: values?.location,
                organization: values?.organization,
                startTime: moment(values?.startTime).toISOString(),
                endTime: moment(values?.endTime).toISOString(),
                prize: +values?.prize,
                currency: values?.currency,
                promotion: values?.promotion?.src,
            };

            AdminService.createTournament(payload)
                .then((res: any) => {
                    history.push(Routes.tournamentList.path);
                    CreateAlert({ message: "Create tournament successfully.", type: "success" });
                })
                .catch((err: any) => {
                    CreateAlert({ message: err?.message, type: "danger" });
                })
                .finally(() => {
                    
                });
        },
    });

    return (
        <div className="tournament-create-page container-fluid">
            <div className="row">
                <div className="col-6">
                    <div className="tournament-create-page__content">
                        <div className="title">Create Tournament</div>
                        <div className="form">
                            <div className="row">
                                <div className="col-12 input-container">
                                    <InputWraper
                                        label={"Tour"}
                                        inputProps={getInputProps("tourName")}
                                        component={InputText}
                                    />
                                </div>
                                <div className="col-6 input-container">
                                    <InputWraper
                                        label={"Location"}
                                        inputProps={getInputProps("location")}
                                        component={InputText}
                                    />
                                </div>
                                <div className="col-6 input-container">
                                    <InputWraper
                                        label={"Organization"}
                                        inputProps={getInputProps("organization")}
                                        renderInput={(props) => (
                                            <InputSelect
                                                {...props}
                                                options={organizationList}
                                                isClearable={false}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-6 input-container">
                                    <InputWraper
                                        label={"Start Time"}
                                        inputProps={getInputProps("startTime")}
                                        component={InputDateTime}
                                    />
                                </div>
                                <div className="col-6 input-container">
                                    <InputWraper
                                        label={"End Time"}
                                        inputProps={getInputProps("endTime")}
                                        component={InputDateTime}
                                    />
                                </div>
                                <div className="col-6 input-container">
                                    <InputWraper
                                        label={"GTD"}
                                        inputProps={getInputProps("prize")}
                                        component={InputNumber}
                                    />
                                </div>
                                <div className="col-6 input-container">
                                    <InputWraper
                                        label={"Currency"}
                                        inputProps={getInputProps("currency")}
                                        renderInput={(props) => (
                                            <InputSelect
                                                {...props}
                                                options={currencyOptions}
                                                value={getInputProps("currency").value}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-12 input-container">
                                    <InputWraper
                                        label={"Promotion"}
                                        inputProps={getInputProps("promotion")}
                                        component={InputImageSingle}
                                    />
                                </div>
                                <div style={{ textAlign: "center" }} className="col-12">
                                    <Button label="Create" buttonType="success" onClick={handleSubmit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
