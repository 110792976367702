import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { InputSelect, InputText } from "../../../../components";
import { translate } from "../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import "./index.scss";
import { useSelector } from "../../../../store";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Routes } from "../../../../AppRoutes";
import { InputImageSingle } from "../../../../components/single-image";

export const UserEdit = (props: any) => {
  const { params }: any = useRouteMatch();
  const history = useHistory();
  const countries = useSelector((state) => state.countries);

  const [userDetail, setUserDetail] = useState<any>(null);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "firstName",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: userDetail?.firstName,
      },
      {
        name: "nick",
        // validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: userDetail?.nick,
      },
      {
        name: "email",
        // validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: userDetail?.email,
      },
      {
        name: "phoneNumber",
        validate: Yup.string().nullable().matches(/^(\+?\d{1,3})?[\s.-]?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/, "Invalid phone number"),
        defaultValue: userDetail?.phoneNumber,
      },
      {
        name: "idCard",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: userDetail?.idCard,
      },
      {
        name: "countryId",
        validate: Yup.string().required("Must be provided."),
        defaultValue: userDetail?.countryId,
      },
      {
        name: "imageUrls1",
        validate: Yup.string().test({
          message: "Must be provided",
          test: function (value) {
            const imageUrls2 = this.resolve(Yup.ref("imageUrls2"));
            const imageUrls3 = this.resolve(Yup.ref("imageUrls3"));
            const imageUrls4 = this.resolve(Yup.ref("imageUrls4"));
            if (!(imageUrls2 || imageUrls3 || imageUrls4 || value)) return false;
            return true;
          },
        }),
        defaultValue: userDetail?.imageUrls[0] !== "" && { file: { name: userDetail?.imageUrls[0] }, src: userDetail?.imageUrls[0] },
      },
      {
        name: "imageUrls2",
        validate: Yup.string().test({
          message: "Must be provided",
          test: function (value) {
            const imageUrls1 = this.resolve(Yup.ref("imageUrls1"));
            const imageUrls3 = this.resolve(Yup.ref("imageUrls3"));
            const imageUrls4 = this.resolve(Yup.ref("imageUrls4"));
            if (!(imageUrls1 || imageUrls3 || imageUrls4 || value)) return false;
            return true;
          },
        }),
        defaultValue: userDetail?.imageUrls[1] !== "" && { file: { name: userDetail?.imageUrls[1] }, src: userDetail?.imageUrls[1] },
      },
      {
        name: "imageUrls3",
        validate: Yup.string().test({
          message: "Must be provided",
          test: function (value) {
            const imageUrls1 = this.resolve(Yup.ref("imageUrls1"));
            const imageUrls2 = this.resolve(Yup.ref("imageUrls2"));
            const imageUrls4 = this.resolve(Yup.ref("imageUrls4"));
            if (!(imageUrls1 || imageUrls2 || imageUrls4 || value)) return false;
            return true;
          },
        }),
        defaultValue: userDetail?.imageUrls[2] !== "" && { file: { name: userDetail?.imageUrls[2] }, src: userDetail?.imageUrls[2] },
      },
      {
        name: "imageUrls4",
        validate: Yup.string().test({
          message: "Must be provided",
          test: function (value) {
            const imageUrls1 = this.resolve(Yup.ref("imageUrls1"));
            const imageUrls2 = this.resolve(Yup.ref("imageUrls2"));
            const imageUrls3 = this.resolve(Yup.ref("imageUrls3"));
            if (!(imageUrls1 || imageUrls2 || imageUrls3 || value)) return false;
            return true;
          },
        }),
        defaultValue: userDetail?.imageUrls[3] !== "" && { file: { name: userDetail?.imageUrls[3] }, src: userDetail?.imageUrls[3] },
      },
    ],
    onSubmit: async (values) => {
      return AdminService.editUser({
        userId: +params.id,
        firstName: values.firstName,
        nick: values.nick,
        email: values.email,
        phone: values.phoneNumber,
        idCard: values.idCard,
        countryId: +values.countryId,
        languageId: +values.countryId,
        imageUrls: [values.imageUrls1.src || "", values.imageUrls2.src || "", values.imageUrls3.src || "", values.imageUrls4.src || ""],
      })
        .then(() => {
          history.push(Routes.userList.path);
          CreateAlert({ message: "Edit user success.", type: "success" });
        })
        .catch((error) => {
          CreateAlert({ message: translate(error.message), type: "danger" });
        });
    },
  });

  useEffect(() => {
    AdminService.getUserDetail(+params.id)
      .then((res) => {
        setUserDetail(res?.result[0]);
      })
      .catch((error) => {
        CreateAlert({ message: translate(error.message), type: "danger" });
      });
  }, [params.id]);

  if (userDetail == null) return null;

  return (
    <div className="user-edit-page container-fluid">
      <div className="row">
        <div className="col-6">
          <div className="user-edit__content">
            <div className="user-edit__content__title">Edit User</div>
            <div className="user-edit__content__form">
              <form className="user-edit" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <InputWraper label={"Full Name"} inputProps={getInputProps("firstName")} component={InputText} />
                  </div>
                  <div className="col-12">
                    <InputWraper label={"Nickname"} inputProps={getInputProps("nick")} component={InputText} />
                  </div>
                  <div className="col-12">
                    <InputWraper label={"Email"} inputProps={getInputProps("email")} component={InputText} />
                  </div>
                  <div className="col-12">
                    <InputWraper label={"Phone"} inputProps={getInputProps("phoneNumber")} component={InputText} />
                  </div>
                  <div className="col-12">
                    <InputWraper label={"Identify Card ID/Passport"} inputProps={getInputProps("idCard")} component={InputText} />
                  </div>
                  <div className="col-12">
                    <InputWraper
                      enableReinitialize
                      label={"National"}
                      inputProps={getInputProps("countryId")}
                      renderInput={(state) => (
                        <InputSelect
                          {...state}
                          isClearable={false}
                          menuPlacement={"top"}
                          asyncData={{
                            response: countries,
                            pathLabel: "niceName",
                            pathValue: "countryId",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <InputWraper enableReinitialize label={"Image"} inputProps={getInputProps("imageUrls1")} component={InputImageSingle} />
                  </div>
                  <div className="col-6">
                    <InputWraper enableReinitialize label={[<span style={{visibility: 'hidden'}}>.</span>]} inputProps={getInputProps("imageUrls2")} component={InputImageSingle} />
                  </div>
                  <div className="col-6">
                    <InputWraper enableReinitialize inputProps={getInputProps("imageUrls3")} component={InputImageSingle} />
                  </div>
                  <div className="col-6">
                    <InputWraper enableReinitialize inputProps={getInputProps("imageUrls4")} component={InputImageSingle} />
                  </div>
                </div>
                <div>
                  <Button
                    // className="mb20"
                    label="Edit"
                    // onClick={props.onClick}
                    isMiddle
                    buttonType="success"
                    className="btnBan"
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
