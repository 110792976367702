import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getLocaleKey, translate } from "../../../languages";
import { ITableStructureItem, Table } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { useSelector } from "../../../store";
import { withConfigWraper } from "../wraper";

type Props = {};

const Activities = withConfigWraper((props: Props) => {
  const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const structure: ITableStructureItem[] = [
    {
      name: "USER ID",
      key: "userId",
      // render: (item) => {
      //   return <span className="email">{item.userId}</span>;
      // },
    },
    {
      name: "USER ROLE",
      key: "userRole",
      // render: (item) => {
      //   return <span className="email">{item.userRole}</span>;
      // },
    },
    {
      name: "EMAIL",
      key: "email",
      className: "email"
      // render: (item) => {
      //   return <span className="email">{item.email}</span>;
      // },
    },
    {
      name: "ACTION",
      key: "action",
      // render: (item) => {
      //   return <span className="email">{item.action}</span>;
      // },
    },
    {
      name: "DESCRIPTION",
      key: "description",
      // render: (item) => {
      //   return <span className="email">{item.description}</span>;
      // },
    },
    {
      name: "CREATED DATE",
      render: (item) => {
        const date = new Date(item.created);
        return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
      },
    },
  ];

  // if (!(user.userRole === "SUPER_ADMIN")) history.push(Routes.changePassword.path);
  if (!(user.userRole === "SUPER_ADMIN")) history.push(Routes.dashboard.path);

  return (
    <div className="row mb20 activities-page">
      <div className="col-sm-12">
        <Table
          hasOrderColumn
          itemPerPages={10}
          filters={[
            {
              name: "Email",
              key: "email",
              input: TableFilterInputText,
            },

            {
              name: translate("Action"),
              key: "action",
              input: (s) => (
                <TableFilterInputSelect
                  {...s}
                  options={ConfigService.getListActionActivity()}
                  // isClearable={true}
                  // isSearchable={false}
                />
              ),
            },
            {
              name: translate("time"),
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: startOfMonth, //new Date(last1MothDate),
                toDate: endOfDay, //new Date(),
              },
            },
          ]}
          structure={structure}
          fetchData={async (params) => {
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
            return ConfigService.getActivitiesSetting({
              page: params.pageNumber,
              pageSize: params.limit,
              ...params,
            });
          }}
        />
      </div>
    </div>
  );
});

export default Activities;
