import React, { FC } from "react";
import "react-calendar/dist/Calendar.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./main.scss";
import { PageAuthLogin } from "./pages/auth";
import { PageClaimList } from "./pages/claim";
import Activities from "./pages/config/activities";
import ChangePassword from "./pages/config/change-password";
import { MaintenanceConfig } from "./pages/config/maintenance/maintenanceConfig";
import RolePermission from "./pages/config/role-permission";
import { WithdrawConfig } from "./pages/config/withdraw/withdrawConfig";
import { PageDashboardSuperAdmin } from "./pages/dashboard-super-admin";
import { PageExperienceGameList } from "./pages/experience-game";
import { PageFranchiseLeaderTable } from "./pages/franchise/leaderTable";
import { PageFranchiseLevelSettingHistory } from "./pages/franchise/level-setting-history";
import { PageFranchiseTable } from "./pages/franchise/table";
import { PageFranchiseTree } from "./pages/franchise/tree";
import { GameCreatePage, GameEditPage, GameListPage, OrdersPage, SupplierListPage } from "./pages/game-management";
import { SupplierCreatePage } from "./pages/game-management/suppliers/add";
import { SupplierEditPage } from "./pages/game-management/suppliers/edit";
import { PageGameOrdersList } from "./pages/game-orders";
import { ReportSystem } from "./pages/report/system";
import { AirdropPage } from "./pages/transaction/airdrop";
import { DepositSupport } from "./pages/transaction/deposit-support/DepositSupport";
import { TransactionDemoList } from "./pages/transaction/transaction/TransactionDemoList";
import { TransactionList } from "./pages/transaction/transaction/TransactionList";
import { TransactionAdminWithdrawList } from "./pages/transaction/withdraw-to-admin-wallet/TransactionAdminWithdrawList";
import { WithdrawProgress } from "./pages/transaction/withdraw/WithdrawProgress";
import { UserList, UserReviewPage } from "./pages/user";
import { PageUserAffiliation } from "./pages/user/affiliation";
import { PageKYC } from "./pages/user/kyc";
import { PageUserKYCList } from "./pages/user/kyc/KYCList";
import { UserDetail } from "./pages/user/user/UserDetail";
import { IPageProps, withPageWraper } from "./pages/wraper";
import NewsPage from "./pages/config/news";
import { CreateNewsPage } from "./pages/config/news/createNews";
import { EditNewsPage } from "./pages/config/news/editNews";
import { DepositFiat } from "./pages/fiat/deposit";
import { WithdrawFiat } from "./pages/fiat/withdraw";
import PageFranchiseCommissionHistory from "./pages/franchise/commission";
import { UserPromotion } from "./pages/user/promotion";
import UserWithdrawLimit from "./pages/config/user-withdraw-limit";
import { BalanceDIG } from "./pages/dig/balance";
import { DepositDIG } from "./pages/dig/deposit";
import { WithdrawDIG } from "./pages/dig/withdraw";
import { DeviceList } from "./pages/dig/device/list";
import { DeviceHistory } from "./pages/dig/device/history";
import { BankTransferHistory } from "./pages/dig/bank/transfer-history";
import { BankList } from "./pages/dig/bank/list";
import { BankMatchingHistory } from "./pages/dig/bank/matching-history";
import { ReportAgent } from "./pages/agent/report";
import { TournamentList } from "./pages/tournament/list";
import { TournamentCreatePage } from "./pages/tournament/create";
import { TournamentEditPage } from "./pages/tournament/edit";
import { UserEdit } from "./pages/user/user/edit";
import { PackageList } from "./pages/package/list";
import BuyIn from "./pages/buy-in";

export const Routes = {
  // dashboard: {
  //     path: '/',
  //     component: PageDashboard,
  // },
  dashboard: {
    path: "/",
    component: UserList,
  },
  // dashboardSuperAdmin: {
  //   path: "/page-dashboard-super-admin",
  //   component: PageDashboardSuperAdmin,
  // },
  userList: {
    path: "/user-list",
    component: UserList,
  },
  userEdit: {
    path: "/user/:id/edit",
    component: UserEdit,
    renderPath: (id: number) => `/user/${id}/edit`,
  },
  userReview: {
    path: "/user-review",
    component: UserReviewPage,
  },
  tournamentList: {
    path: "/tournament",
    component: TournamentList,
  },
  tournamentCreate: {
    path: "/tournament/create",
    component: TournamentCreatePage,
  },
  tournamentEdit: {
    path: "/tournament/:id/edit",
    component: TournamentEditPage,
    renderPath: (id: number) => `/tournament/${id}/edit`,
  },
  packageList: {
    path: "/package",
    component: PackageList,
  },
  // userPromotion: {
  //   path: "/user-promotion",
  //   component: UserPromotion,
  // },
  // userKYCList: {
  //   path: "/kyc-list",
  //   component: PageUserKYCList,
  // },
  // userAffiliation: {
  //   path: "/user-affiliation",
  //   component: PageUserAffiliation,
  // },
  franchiseTable: {
    path: "/franchise-table",
    component: PageFranchiseTable,
  },
  franchiseTree: {
    path: "/franchise-tree",
    component: PageFranchiseTree,
  },
  // franchiseLevelSettingHistory: {
  //   path: "/franchise-level-setting-history",
  //   component: PageFranchiseLevelSettingHistory,
  // },
  // franchiseCommissionHistory: {
  //   path: "/franchise-commission-history",
  //   component: PageFranchiseCommissionHistory,
  // },
  // franchiseLeader: {
  //   path: "/franchise-leader",
  //   component: PageFranchiseLeaderTable,
  // },
  // userDetail: {
  //   path: "/user-list/:email",
  //   component: UserDetail,
  //   renderPath: (email: string) => `/user-list/${email}`,
  // },
  transactionList: {
    path: "/transaction-list",
    component: TransactionList,
  },
  buyIn: {
    path: "/buy-in",
    component: BuyIn,
  },
  // transactionDemoList: {
  //   path: "/transactions-demo",
  //   component: TransactionDemoList,
  // },
  // withdraw: {
  //   path: "/transactions-withdraws",
  //   component: WithdrawProgress,
  // },
  // AirdropPage: {
  //   path: "/transactions-airdrop",
  //   component: AirdropPage,
  // },
  // kyc: {
  //   path: "/kyc",
  //   component: PageKYC,
  // },
  reportSystem: {
    path: "/system-report",
    component: ReportSystem,
  },
  // depositSupport: {
  //   path: "/transactions-deposit-support",
  //   component: DepositSupport,
  // },
  // TransactionAdminWithdrawList: {
  //   path: "/transactions-withdraw-to-admin-wallet",
  //   component: TransactionAdminWithdrawList,
  // },
  // withdrawConfig: {
  //   path: "/server-config-withdraw",
  //   component: WithdrawConfig,
  // },
  // maintenanceConfig: {
  //   path: "/server-config-maintenance",
  //   component: MaintenanceConfig,
  // },
  // rolePermission: {
  //   path: "/server-config-role-permission",
  //   component: RolePermission,
  // },
  changePassword: {
    path: "/server-config-change-password",
    component: ChangePassword,
  },
  activities: {
    path: "/server-config-activities",
    component: Activities,
  },
  // ClaimList: {
  //   path: "/claim-list",
  //   component: PageClaimList,
  // },
  // GameOrdersList: {
  //   path: "/game-orders-list",
  //   component: PageGameOrdersList,
  // },
  // OrdersPage: {
  //   path: "/game-management-orders",
  //   component: OrdersPage,
  // },
  // SupplierListPage: {
  //   path: "/game-management-supplier",
  //   component: SupplierListPage,
  // },
  // SupplierCreatePage: {
  //   path: "/game-management-supplier/create",
  //   component: SupplierCreatePage,
  // },
  // SupplierEditPage: {
  //   path: "/game-management-supplier/edit/:id",
  //   component: SupplierEditPage,
  // },
  // GameListPage: {
  //   path: "/game-management-game",
  //   component: GameListPage,
  // },
  // GameCreatePage: {
  //   path: "/game-management-game/create",
  //   component: GameCreatePage,
  // },
  // GameEditPage: {
  //   path: "/game-management-game/edit/:id",
  //   component: GameEditPage,
  // },
  // ExperienceGameList: {
  //   path: "/experience-game-list",
  //   component: PageExperienceGameList,
  // },
  // News: {
  //   path: "/server-config/news",
  //   component: NewsPage,
  // },
  // CreateNews: {
  //   path: "/server-config/news/create",
  //   component: CreateNewsPage,
  // },
  // EditNews: {
  //   path: "/server-config/news/edit",
  //   component: EditNewsPage,
  // },
  // DepositFiat: {
  //   path: "/fiat/deposit",
  //   component: DepositFiat,
  // },
  // WithdrawFiat: {
  //   path: "/fiat/withdraw",
  //   component: WithdrawFiat,
  // },
  // UserWithdrawLimit: {
  //   path: "/server-config/user-withdraw-limit",
  //   component: UserWithdrawLimit,
  // },
  // withdrawDIG: {
  //   path: "/dig/withdraw",
  //   component: WithdrawDIG,
  // },
  // depositDIG: {
  //   path: "/dig/deposit",
  //   component: DepositDIG,
  // },
  // balanceDIG: {
  //   path: "/dig/balance",
  //   component: BalanceDIG,
  // },
  // deviceDIG: {
  //   path: "/dig/device",
  //   component: DeviceList,
  // },
  // deviceHistoryDIG: {
  //   path: "/dig/device/history",
  //   component: DeviceHistory,
  // },
  // bankDIG: {
  //   path: "/dig/bank/",
  //   component: BankList,
  // },
  // bankTransferHistoryDIG: {
  //   path: "/dig/bank/transfer-history",
  //   component: BankTransferHistory,
  // },
  // bankMatchingHistoryDIG: {
  //   path: "/dig/bank/matching-history",
  //   component: BankMatchingHistory,
  // },
  // agent: {
  //   path: "/agent",
  //   component: ReportAgent,
  // },
};

export const AppRoutes: FC = () => {
  return (
    <Router>
      <Switch>
        {Object.values(Routes).map((item: any, key) => {
          return (
            <Route
              key={key}
              exact
              path={item.path}
              component={withPageWraper((props: IPageProps) => {
                return <item.component {...props} />;
              })}
            />
          );
        })}

        <Route path="/authentication/login" component={PageAuthLogin} />
      </Switch>
    </Router>
  );
};
