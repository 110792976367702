import { TableFilterInputText } from "../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../components/table-filter-inputs/select";
import { Button, CreateAlert, Icon, InputWraper, Table, useForm } from "../../modules";
import React, { useEffect, useState } from "react"
import { AdminService } from "../../services/admin";
import { InputSelect, InputText } from "../../components";
import "./index.scss"
import * as Yup from "yup";
import { PopupWraper } from "../../components/popup";
import moment from "moment";
import { translate } from "../../languages";


const BuyIn = () => {
    // const [params, setParams] = useState([] as any[]);
    const [openPopUpUserId, setOpenPopUpUserId] = useState<any>();
    const [openPopUpEventId, setOpenPopUpEventId] = useState<any>();
    const [currentMember, setCurrentMember] = useState<{userId:any, idCard:any, name:string}>();
    const [currentEvent, setCurrentEvent] = useState<{eventId:any,tourEventId:any, tourEventName:string, buyInFee:string}>();
    const { handleSubmit, getInputProps, isSubmitting, } = useForm({
        structure: [
          {
            name: "userId",
          },
          {
            name: "eventId",
            isDisabled: (currentMember != null) ? false : true,
          },
        ],
        onSubmit: async () => {
          if(currentMember?.userId!==undefined && currentEvent?.eventId!==undefined)
            return AdminService.createBuyIn(currentMember.userId, currentEvent.tourEventId).then(res=>{
          if(res.success){
            CreateAlert({message:translate("SUCCESS"), type:"success"})
          }}).catch(e=>{ CreateAlert({ message: translate(e.message), type: "danger" });
        })
        },

      });

    let structure:any = [
        {
            name: "C9 ID",
            key: "userId",
            render: (item: any) => item?.userId ?? "N/A",
            copyable: (item: any) => item?.userId,
        },
        {
            name: "FULL NAME",
            key: "firstName",
            render: (item: any) => item?.firstName ?? "N/A",
            copyable: (item: any) => item?.firstName,
        },
        {
            name: "NICK NAME",
            key: "nick",
        },
        {
            name: "EMAIL",
            key: "mail",
        },
        {
            name: "PHONE",
            key: "phoneNumber",
            render: (item: any) => item?.phoneNumber ?? "N/A",
            copyable: (item: any) => item?.phoneNumber,
        },
        {
            name: "IDENTITY CARD/PASSPORT",
            key: "idCard",
            copyable: (item: any) => item?.idCard,
        },
        {
            name: "NATIONALITY",
            key: "languageName",
            copyable: (item: any) => item?.languageName,
        },
        {
            name: "ACTIONS",
            key: "actions",
            render: (item:any) => {
              return (
                <div className="action">
                  <div className="action__edit" title="Pick this user" onClick={() => {
                    setCurrentMember({idCard:item?.idCard, userId:item?.userId, name:item?.firstName})
                    setOpenPopUpUserId(false)
                  }}>
                    <Icon.AddIcon />
                  </div>
                </div>
              );
            },
          },
    ]
    let eventStructure = [
        {
          name: "EVENT ID",
          key: "eventRef",
          render: (item: any) => item?.eventRef ?? "N/A",
        },
        {
          name: "EVENT NAME",
          key: "tourEventName",
          render: (item: any) => item?.tourEventName ?? "N/A",
        },
        {
          name: "BUY-IN+FEE",
          key: "buyInFee",
          render: (item: any) => (item?.buyInFee != null ? `${new Intl.NumberFormat("en-US").format(item?.buyInFee)} ${item?.currency ?? ""}` : "N/A"),
        },
        {
          name: "REG END",
          key: "regEnd",
          render: (item: any) => (item?.regEnd != null ? moment(item?.regEnd).format("L HH:mm:ss") : "N/A"),
        },
        {
          name: "START TIME",
          key: "startTime",
          render: (item: any) => (item?.startTime != null ? moment(item?.startTime).format("L HH:mm:ss") : "N/A"),
        },
        {
          name: "ACTION",
          key: "action",
          render: (item: any) => {
            return (
              <div className="action">
                <div className="action__edit" title="Pick this user" onClick={() => {
                    setCurrentEvent({eventId:item?.eventRef, tourEventId:item?.tourEventId, tourEventName:item?.tourEventName, buyInFee:`${new Intl.NumberFormat("en-US").format(item?.buyInFee)} ${item?.currency ?? ""}`})
                    setOpenPopUpEventId(false)
                  }}>
                    <Icon.AddIcon />
                  </div>
              </div>
            );
          },
        },
      ];

    return <>
    <form onSubmit={handleSubmit} className="buyin--page">
    <InputWraper
        label={"Pick a User (Full Name - IDentity Card/Passport)"}
      inputProps={getInputProps("userId")}
      component={()=><InputText name={"userId"} value={currentMember?.name && currentMember.userId ? `${currentMember?.name??"N/A"} - ${currentMember?.idCard??"N/A"}`:""} onChange={function (value: any): void {
      } } onTouched={function (status?: boolean): void {
      } }
      placeholder="USER ID"
      />}
      onClick={(e)=>{setOpenPopUpUserId(true)}}
    />
    <InputWraper
        label={"Pick a event"}
      inputProps={getInputProps("eventId")}
      component={()=><InputText name={"eventId"} value={currentEvent?.tourEventName && currentEvent.eventId && currentEvent.tourEventId && currentEvent.buyInFee? `${currentEvent?.eventId??"N/A"} - ${currentEvent?.tourEventName??"N/A"} - ${currentEvent?.buyInFee??"N/A"}`:""} onChange={function (value: any): void {
      } } onTouched={function (status?: boolean): void {
      } }
      placeholder="EVENT ID"
      />}
      onClick={(e)=>{
        if (currentMember != null) {
          setOpenPopUpEventId(true)
        }
      }}
    />
    <Button isLoading={isSubmitting} type="submit" label="Submit" disabled={!(currentEvent&&currentMember)}/>
    
  </form>
  {openPopUpUserId && (
    <PopupWraper
      center
      title="PICK A USER"
      className="buyin--popup"
      onClose={() => {
        setOpenPopUpUserId(false);
      }}
    >
      <Table
      isOpenFilter
      itemPerPages={5}
      filters={[
          {
              name: "Search",
              key: "keyword",
              input: e => <TableFilterInputText {...e} placeholder="Name, Email" />,
          },
      ]}
      structure={structure}
      fetchData={async (state) => {
          let params = { ...state };
          return AdminService.getListUser({
            page: params.pageNumber,
            numberOfItemsPerPage: 5,
            ...params,
          }).then((res)=>res)
      }}
      /> 
    </PopupWraper>
  )}
{openPopUpEventId && (
    <PopupWraper
      center
      title="PICK A EVENT"
      onClose={() => {
        setOpenPopUpEventId(false);
      }}
      className="buyin--popup"
    >
      <Table
      isOpenFilter
      itemPerPages={5}
      filters={[
          {
              name: "Search",
              key: "keyword",
              input: e => <TableFilterInputText {...e} placeholder="Name, ..." />,
          },
         
      ]}
      structure={eventStructure}
      fetchData={async (state) => {
          let params ={...state}
          return AdminService.getEventList({
              tourScheduleId: 0,
              userId: currentMember?.userId,
              page: params.pageNumber,
              pageSize: 5,
              ...params,
            }).then((res: any) => {
              return {
                data: res?.result?.data,
                count: res?.result?.total,
                
              };
            });
      }}
      /> 
    </PopupWraper>
  )}
  </>
}
 
export default BuyIn;

