import React, { memo, useState } from "react";
import XLSX from "xlsx";
import { AdminService } from "../../../services/admin";
import { Button, CreateAlert, Icon, Table } from "../../../modules";
import moment from "moment";
import { Routes } from "../../../AppRoutes";
import { useHistory } from "react-router-dom";
import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";

export const TournamentList = () => {
  const history = useHistory();

  const [params, setParams] = useState([] as any[]);

  const structure = [
    {
      name: "TOUR",
      key: "tourName",
      render: (item: any) => <span style={{ color: "#4680FF" }}>{item?.tourName ?? "N/A"}</span>,
      copyable: (item: any) => item?.tourName,
    },
    {
      name: "LOCATION",
      key: "location",
      render: (item: any) => item?.location ?? "N/A",
      copyable: (item: any) => item?.location,
    },
    {
      name: "ORGANIZATION",
      key: "organization",
      render: (item: any) => item?.organization ?? "N/A",
      copyable: (item: any) => item?.organization,
    },
    {
      name: "DATE",
      key: "date",
      render: (item: any) =>
        `${item?.startTime != null ? moment(item.startTime).format("L") : "N/A"} - ${
          item?.endTime != null ? moment(item.endTime).format("L") : "N/A"
        }`,
      copyable: (item: any) => `${item?.startTime != null ? moment(item.startTime).format("L") : "N/A"} - ${item?.endTime != null ? moment(item.endTime).format("L") : "N/A"}`,
    },
    {
      name: "GTD",
      key: "prize",
      render: (item: any) => (item?.prize != null ? `${new Intl.NumberFormat("en-US").format(item?.prize)} ${item?.currency ?? ""}` : "N/A"),
      copyable: (item: any) => item?.prize,
    },
    {
      name: "TOUR STATUS",
      key: "status",
      render: (item: any) => <span className={`status status--${item?.status?.toLowerCase()}`}>{item?.status ?? "N/A"}</span>,
    },
    {
      name: "ACTIONS",
      key: "actions",
      render: (item: any) => {
        return (
          <div className="action">
            <div className="action__edit" onClick={() => history.push(Routes.tournamentEdit.renderPath(item?.tourId))}>
              <Icon.ActionEditIcon />
            </div>
          </div>
        );
      },
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await AdminService.getTournamentList({
          ...params,
          page: 1,
          pageSize: 10000,
        });

        const data = response.data;

        const fileHead = structure.map((v) => v.name);
        const dataExport = [
          fileHead,
          ...data.map((item: any) =>
            structure.map((column, index) => {
              if (!column.key) return "";
              if (column.key === "date")
                return `${item?.startTime != null ? moment(item.startTime).format("L") : "N/A"} - ${
                  item?.endTime != null ? moment(item.endTime).format("L") : "N/A"
                }`;
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(wb, `Tournament List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
          type: "binary",
        });

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error) {
        // @ts-ignore
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <div className="tournament-list">
      <div className="d-flex justify-content-between button-container">
        <Button className="mb20" label="Export to Excel" buttonType="success" onClick={handleExportExcel} />

        <Button className="mb20" label="Add" icon={Icon.AddNewIcon} buttonType="success" onClick={() => history.push(Routes.tournamentCreate.path)} />
      </div>

      <Table
        hasOrderColumn
        hasSearchButton
        isOpenFilter
        filters={[
          {
            name: "Tour",
            key: "name",
            input: TableFilterInputText,
          },
          {
            name: "Status",
            key: "status",
            input: (e: any) => (
              <TableFilterInputSelect
                {...e}
                options={[
                  { label: "UPCOMING", value: "UPCOMING" },
                  { label: "ONGOING", value: "ONGOING" },
                  { label: "COMPLETED", value: "COMPLETED" },
                ]}
                isClearable={true}
                isSearchable={false}
              />
            ),
          },
        ]}
        structure={structure}
        fetchData={async (params) => {
          setParams(params);
          return AdminService.getTournamentList({
            page: params.pageNumber,
            pageSize: params.limit,
            ...params,
          });
        }}
      />
    </div>
  );
};
